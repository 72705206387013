import React from "react";

const Footer = () => {
    return (
        <nav className="navbar bottom feedback-bottom-nav text-center branded_logo border-top border-bottom">
            <div className="text-center">
                <span className="nfcv-footer-span">
                    <a href="https://realtimefeedback.com" className="learn_more_about_feedback_link" target="_blank" rel="noreferrer">
                        Get Real Time Feedback for Your Business
                    </a>
                </span>
                <br />

                <a href="https://www.realtimefeedback.com/terms-conditions/" target="_blank" rel="noreferrer">Terms and Conditions</a> 
                |
                <a href="https://www.realtimefeedback.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
            </div>

            <div>
                <a href="https://realtimefeedback.com" className="learn_more_about_feedback_link nfcv-footer-img-wrapper" target="_blank" rel="noreferrer">
                    <img src="https://tellmy.co/img/feedback-qr-logo.png" alt="" />
                </a>
            </div>
        </nav>
    )
};

export default Footer; 