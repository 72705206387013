import React from 'react';

const Header = () => {
  return (
    <nav className='nfcv-header'>
      <span className='nfcv-logo'>
        <img src="https://s3.amazonaws.com/fbapp.assets/data/tellmyco/branded_logo/generic.png?mtime=1694450533" alt="COMPANY LOGO PLACEHOLDER" />
      </span>
    </nav>
  );
};

export default Header;
