import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import PublicGoogleSheetsParser from 'public-google-sheets-parser'
import Header from 'components/Header'
import Footer from 'components/Footer'

const Home = () => {
    const [items, setItems] = useState([]);
    let { id } = useParams();

    useEffect(() => {
        const parser = new PublicGoogleSheetsParser('1PsMr9IZr9ZlllY4SyRJpjNqUPzO9PJddzEjKbg1MFVw')
        parser.parse().then(data => {
            setItems(data)
        })
    }, [])

    function isURL(str) {
        if (typeof str !== 'string') {
            return false; // Not a string, so not a URL
        }
        return str.startsWith("http://") || str.startsWith("https://");
    }

    return (
        <div className='nfcv-wrapper'>
            <Header />
            
            <div>
            {items.map((item, index) => {
                let realSpreadsheetIndex = index + 2;
                if (parseInt(id) && parseInt(id) === realSpreadsheetIndex) {
                    return (
                        <div key={`p-${realSpreadsheetIndex}`} className='nfcv-box'>
                            {Object.keys(item).map((key) => (
                                <div key={`${realSpreadsheetIndex}-${key}`} className={`nfcv-box-row ${key === 'VIN' ? 'nfcv-box-row--spacer' : ''}`}>
                                    {isURL(item[key]) ? (
                                        <a href={item[key]} className='nfcv-box-label nfcv-box-label--link'>{key}</a>
                                    ) : (
                                        <>
                                            <span className='nfcv-box-label'>{key}</span>
                                            <span className='nfcv-box-value'>{item[key]}</span>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    );
                }
                return null; // Add this return statement
            })}
            </div>

            <Footer />
        </div>
    )
}

export default Home;